import {
    Backdrop,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select, Tooltip
} from "@mui/material";
import SoftButton from "../components/SoftButton";
import Grid2 from '@mui/material/Unstable_Grid2';
import {useEffect, useState} from "react";
import {useTheme} from '@mui/material/styles';
import SoftInput from "../components/SoftInput";
import Separator from "../components/Separator";
import {Warning} from "@mui/icons-material";
import DropzoneArea from "./nested/Dropzone";
import SoftTypography from "../components/SoftTypography";
import theme from "assets/theme";
import {ThemeProvider} from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import * as React from "react";
import BasicLayout from "../components/BasicLayout";
import SoftBox from "../components/SoftBox";
import {Vortex} from "react-loader-spinner";
import RetourenAPI from "../api/RetourenAPI";

export default function Datenschutz({token}) {
    const [loading, setLoading] = useState(true);
    const [imprintData, setImprintData] = useState(undefined);

    useEffect(() => {
        RetourenAPI.generateImprint(token).then(res => {
            setImprintData(res.data);
        }).catch(error => {
            setImprintData({
                "id": 1,
                "companyName": "Aufzug Gräser GbR",
                "responsibleFirstName": "Philipp",
                "username": "gcrimede@gmail.com",
                "responsibleSecondName": "Gräser",
                "email": "gcrimede@gmail.com",
                "phone": "04031108456",
                "street": "Cuxhavener Straße",
                "houseNumber": "386",
                "zip": 21149,
                "city": "Hamburg",
                "country": "DE",
                "vat": "DE336325022",
                "complete": true
            })
        }).finally(() => setLoading(false));
    }, []);

    const loadingScene = () => (<SoftBox display="flex" justifyContent="center">
        <div className='pulse'>
            <Vortex
                visible={true}
                height="80"
                width="80"
                ariaLabel="vortex-loading"
                wrapperStyle={{}}
                wrapperClass="vortex-wrapper"
                colors={['#ffd6ff', '#e7c6ff', '#c8b6ff', '#b8c0ff', '#bbd0ff', '#cae9ff']}
            />
        </div>
    </SoftBox>)

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <BasicLayout
                title={undefined}
                description={undefined}
                color='primary'
                token={token}
            >
                <Card sx={{minHeight: '70vh', minWidth: 999}}>
                    <CardContent>
                        {loading ? <Backdrop
                                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                                open={loading}
                            >
                                {loadingScene()}
                            </Backdrop>
                            :
                            <Grid2 container direction='row' justifyContent='left' textAlign='left' spacing={2}>
                                <Grid2 item>
                                    <SoftTypography>
                                        <h1>Datenschutzerklärung</h1><br/>
                                        <p>Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der
                                            EU-Datenschutzgrundverordnung
                                            (DSGVO), ist:</p>
                                        <p>{imprintData?.companyName}<br/>
                                            vertreten durch: {imprintData?.responsibleFirstName}&nbsp;{imprintData?.responsibleSecondName}<br/>
                                            {imprintData?.street}&nbsp;{imprintData?.houseNumber}<br/>
                                            {imprintData?.zip}&nbsp;{imprintData?.city}<br/></p>
                                        <h2>Ihre Betroffenenrechte</h2><br/>
                                        <p>Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie
                                            jederzeit folgende
                                            Rechte ausüben:</p>
                                        <ul>
                                            <li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung
                                                (Art. 15 DSGVO),
                                            </li>
                                            <li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
                                            <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
                                            <li>Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund
                                                gesetzlicher Pflichten
                                                noch nicht löschen dürfen (Art. 18 DSGVO),
                                            </li>
                                            <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO)
                                                und
                                            </li>
                                            <li>Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt
                                                haben oder einen
                                                Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).
                                            </li>
                                        </ul>
                                        <p>Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit
                                            mit Wirkung für die
                                            Zukunft widerrufen.</p>
                                        <p>Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde
                                            wenden, z. B. an die
                                            zuständige Aufsichtsbehörde des Bundeslands Ihres Wohnsitzes oder an die für
                                            uns als
                                            verantwortliche Stelle zuständige Behörde.</p>
                                        <p>Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit
                                            Anschrift finden Sie
                                            unter: <a
                                                href="https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html"
                                                target="_blank"
                                                rel="noopener nofollow">https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html</a>.
                                        </p>
                                        <p></p><h2>Erfassung allgemeiner Informationen beim Besuch unserer Website</h2>
                                        <br/>
                                        <h3>Art und Zweck der Verarbeitung:</h3><br/>
                                        <p>Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht registrieren
                                            oder anderweitig
                                            Informationen übermitteln, werden automatisch Informationen allgemeiner
                                            Natur erfasst. Diese
                                            Informationen (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das
                                            verwendete
                                            Betriebssystem, den Domainnamen Ihres Internet-Service-Providers, Ihre
                                            IP-Adresse und
                                            ähnliches. </p>
                                        <p>Sie werden insbesondere zu folgenden Zwecken verarbeitet:</p>
                                        <ul>
                                            <li>Sicherstellung eines problemlosen Verbindungsaufbaus der Website,</li>
                                            <li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
                                            <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
                                            <li>zur Optimierung unserer Website.</li>
                                        </ul>
                                        <p>Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu ziehen.
                                            Informationen dieser
                                            Art werden von uns ggfs. anonymisiert statistisch ausgewertet, um unseren
                                            Internetauftritt und
                                            die dahinterstehende Technik zu optimieren. </p>
                                        <h3>Rechtsgrundlage und berechtigtes Interesse:</h3><br/>
                                        <p>Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres
                                            berechtigten
                                            Interesses an der Verbesserung der Stabilität und Funktionalität unserer
                                            Website.</p>
                                        <h3>Empfänger:</h3><br/>
                                        <p>Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb
                                            und die Wartung
                                            unserer Webseite als Auftragsverarbeiter tätig werden.</p>
                                        <p></p><h3>Drittlandtransfer:</h3><br/>
                                        <p>Die erhobenen Daten werden ggfs. in folgende Drittländer übertragen: </p>
                                        <p>Nein</p>
                                        <p>Folgende Datenschutzgarantien liegen vor: </p>
                                        <p></p><h3>Speicherdauer:</h3><br/>
                                        <p>Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung nicht mehr
                                            erforderlich sind.
                                            Dies ist für die Daten, die der Bereitstellung der Website dienen,
                                            grundsätzlich der Fall, wenn
                                            die jeweilige Sitzung beendet ist. </p>
                                        <p> Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens 14
                                            Tagen der Fall. Eine
                                            darüberhinausgehende Speicherung ist möglich. In diesem Fall werden die
                                            IP-Adressen der Nutzer
                                            anonymisiert, sodass eine Zuordnung des aufrufenden Clients nicht mehr
                                            möglich ist.</p>
                                        <p></p><h3>Bereitstellung vorgeschrieben oder erforderlich:</h3><br/>
                                        <p>Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder
                                            gesetzlich noch vertraglich
                                            vorgeschrieben. Ohne die IP-Adresse ist jedoch der Dienst und die
                                            Funktionsfähigkeit unserer
                                            Website nicht gewährleistet. Zudem können einzelne Dienste und Services
                                            nicht verfügbar oder
                                            eingeschränkt sein. Aus diesem Grund ist ein Widerspruch
                                            ausgeschlossen. </p>
                                        <p></p><h2>Cookies</h2><br/>
                                        <p>Wie viele andere Webseiten verwenden wir auch so genannte „Cookies“. Bei
                                            Cookies handelt es sich
                                            um kleine Textdateien, die auf Ihrem Endgerät (Laptop, Tablet, Smartphone
                                            o.ä.) gespeichert
                                            werden, wenn Sie unsere Webseite besuchen. </p>
                                        <p>Sie können Sie einzelne Cookies oder den gesamten Cookie-Bestand löschen.
                                            Darüber hinaus erhalten
                                            Sie Informationen und Anleitungen, wie diese Cookies gelöscht oder deren
                                            Speicherung vorab
                                            blockiert werden können. Je nach Anbieter Ihres Browsers finden Sie die
                                            notwendigen
                                            Informationen unter den nachfolgenden Links:</p>
                                        <ul>
                                            <li>Mozilla Firefox: <a
                                                href="https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen"
                                                target="_blank"
                                                rel="nofollow noopener">https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen</a>
                                            </li>
                                            <li>Internet Explorer: <a
                                                href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies"
                                                target="_blank"
                                                rel="nofollow noopener">https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies</a>
                                            </li>
                                            <li>Google Chrome: <a
                                                href="https://support.google.com/accounts/answer/61416?hl=de"
                                                target="_blank"
                                                rel="nofollow noopener">https://support.google.com/accounts/answer/61416?hl=de</a>
                                            </li>
                                            <li>Opera: <a href="http://www.opera.com/de/help" target="_blank"
                                                          rel="nofollow noopener">http://www.opera.com/de/help</a></li>
                                            <li>Safari: <a
                                                href="https://support.apple.com/kb/PH17191?locale=de_DE&viewlocale=de_DE"
                                                target="_blank"
                                                rel="nofollow noopener">https://support.apple.com/kb/PH17191?locale=de_DE&viewlocale=de_DE</a>
                                            </li>
                                        </ul>
                                        <h3>Speicherdauer und eingesetzte Cookies:</h3><br/>
                                        <p>Soweit Sie uns durch Ihre Browsereinstellungen oder Zustimmung die Verwendung
                                            von Cookies
                                            erlauben, können folgende Cookies auf unseren Webseiten zum Einsatz
                                            kommen:</p>
                                        <p>- Cookies zur Authentifizierung bis Ende der Browsersitzung</p>
                                        <h2>Technisch notwendige Cookies </h2><br/>
                                        <h3>Art und Zweck der Verarbeitung: </h3><br/>
                                        <p>Wir setzen Cookies ein, um unsere Website nutzerfreundlicher zu gestalten.
                                            Einige Elemente
                                            unserer Internetseite erfordern es, dass der aufrufende Browser auch nach
                                            einem Seitenwechsel
                                            identifiziert werden kann.</p>
                                        <p>Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung von
                                            Websites für die
                                            Nutzer zu vereinfachen. Einige Funktionen unserer Internetseite können ohne
                                            den Einsatz von
                                            Cookies nicht angeboten werden. Für diese ist es erforderlich, dass der
                                            Browser auch nach einem
                                            Seitenwechsel wiedererkannt wird.</p>
                                        <p>Für folgende Anwendungen benötigen wir Cookies:</p>
                                        <p>Übernahme von Spracheinstellungen</p>
                                        <h3>Rechtsgrundlage und berechtigtes Interesse: </h3><br/>
                                        <p>Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres
                                            berechtigten
                                            Interesses an einer nutzerfreundlichen Gestaltung unserer Website.</p>
                                        <h3>Empfänger: </h3><br/>
                                        <p>Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb
                                            und die Wartung
                                            unserer Website als Auftragsverarbeiter tätig werden.</p>
                                        <p></p><h3>Drittlandtransfer:</h3><br/>
                                        <p>Die erhobenen Daten werden ggfs. in folgende Drittländer übertragen: </p>
                                        <p>Nein</p>
                                        <p>Folgende Datenschutzgarantien liegen vor: </p>
                                        <p></p><h3>Bereitstellung vorgeschrieben oder erforderlich:</h3><br/>
                                        <p>Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder
                                            gesetzlich noch vertraglich
                                            vorgeschrieben. Ohne diese Daten ist jedoch der Dienst und die
                                            Funktionsfähigkeit unserer
                                            Website nicht gewährleistet. Zudem können einzelne Dienste und Services
                                            nicht verfügbar oder
                                            eingeschränkt sein.</p>
                                        <h3>Widerspruch</h3><br/>
                                        <p>Lesen Sie dazu die Informationen über Ihr Widerspruchsrecht nach Art. 21
                                            DSGVO weiter unten.</p>
                                        <p></p><h2>Registrierung auf unserer Website</h2><br/>
                                        <h3>Art und Zweck der Verarbeitung:</h3><br/>
                                        <p>Für die Registrierung auf unserer Website benötigen wir einige
                                            personenbezogene Daten, die über
                                            eine Eingabemaske an uns übermittelt werden. </p>
                                        <p>Zum Zeitpunkt der Registrierung werden zusätzlich folgende Daten erhoben:</p>
                                        <p>- Zeitstempel
                                            <br/><br/>- Generierter Nutzername (E-Mail)</p>
                                        <p>Ihre Registrierung ist für das Bereithalten bestimmter Inhalte und Leistungen
                                            auf unserer Website
                                            erforderlich.</p>
                                        <h3>Rechtsgrundlage:</h3><br/>
                                        <p>Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt auf
                                            Grundlage einer
                                            Einwilligung des Nutzers (Art. 6 Abs. 1 lit. a DSGVO).</p>
                                        <h3>Empfänger:</h3><br/>
                                        <p>Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb
                                            und die Wartung
                                            unserer Website als Auftragsverarbeiter tätig werden.</p>
                                        <p></p><h3>Drittlandtransfer:</h3><br/>
                                        <p>Die erhobenen Daten werden ggfs. in folgende Drittländer übertragen: </p>
                                        <p>Nein</p>
                                        <p>Folgende Datenschutzgarantien liegen vor: </p>
                                        <p></p><h3>Speicherdauer:</h3><br/>
                                        <p>Daten werden in diesem Zusammenhang nur verarbeitet, solange die
                                            entsprechende Einwilligung
                                            vorliegt. </p>
                                        <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3><br/>
                                        <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein
                                            auf Basis Ihrer
                                            Einwilligung. Ohne die Bereitstellung Ihrer personenbezogenen Daten können
                                            wir Ihnen keinen
                                            Zugang auf unsere angebotenen Inhalte gewähren. </p>
                                        <p></p><h2>Erbringung kostenpflichtiger Leistungen</h2><br/>
                                        <h3>Art und Zweck der Verarbeitung:</h3><br/>
                                        <p>Zur Erbringung kostenpflichtiger Leistungen werden von uns zusätzliche Daten
                                            erfragt, wie z.B.
                                            Zahlungsangaben, um Ihre Bestellung ausführen zu können.</p>
                                        <h3>Rechtsgrundlage:</h3><br/>
                                        <p>Die Verarbeitung der Daten, die für den Abschluss des Vertrages erforderlich
                                            ist, basiert auf
                                            Art. 6 Abs. 1 lit. b DSGVO.</p>
                                        <h3>Empfänger:</h3><br/>
                                        <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
                                        <p></p><h3>Drittlandtransfer:</h3><br/>
                                        <p>Die erhobenen Daten werden ggfs. in folgende Drittländer übertragen: </p>
                                        <p>Nein</p>
                                        <p>Folgende Datenschutzgarantien liegen vor: </p>
                                        <p></p><h3>Speicherdauer:</h3><br/>
                                        <p>Wir speichern diese Daten in unseren Systemen bis die gesetzlichen
                                            Aufbewahrungsfristen
                                            abgelaufen sind. Diese betragen grundsätzlich 6 oder 10 Jahre aus Gründen
                                            der ordnungsmäßigen
                                            Buchführung und steuerrechtlichen Anforderungen.</p>
                                        <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3><br/>
                                        <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Ohne die
                                            Bereitstellung
                                            Ihrer personenbezogenen Daten können wir Ihnen keinen Zugang auf unsere
                                            angebotenen Inhalte und
                                            Leistungen gewähren.</p>
                                        <p></p><h2>Newsletter</h2><br/>
                                        <h3>Art und Zweck der Verarbeitung:</h3><br/>
                                        <p>Für die Zustellung unseres Newsletters erheben wir personenbezogene Daten,
                                            die über eine
                                            Eingabemaske an uns übermittelt werden.</p>
                                        <p>Für eine wirksame Registrierung benötigen wir eine valide E-Mail-Adresse. Um
                                            zu überprüfen, dass
                                            eine Anmeldung tatsächlich durch den Inhaber einer E-Mail-Adresse erfolgt,
                                            setzen wir das
                                            „Double-Opt-in“-Verfahren ein. Hierzu protokollieren wir die Anmeldung zum
                                            Newsletter, den
                                            Versand einer Bestätigungsmail und den Eingang der hiermit angeforderten
                                            Antwort. Weitere Daten
                                            werden nicht erhoben. </p>
                                        <h3>Rechtsgrundlage:</h3><br/>
                                        <p>Auf Grundlage Ihrer ausdrücklich erteilten Einwilligung (Art. 6 Abs. 1 lit. a
                                            DSGVO), übersenden
                                            wir Ihnen regelmäßig unseren Newsletter bzw. vergleichbare Informationen per
                                            E-Mail an Ihre
                                            angegebene E-Mail-Adresse. </p>
                                        <p>Die Einwilligung zur Speicherung Ihrer persönlichen Daten und ihrer Nutzung
                                            für den
                                            Newsletterversand können Sie jederzeit mit Wirkung für die Zukunft
                                            widerrufen. In jedem
                                            Newsletter findet sich dazu ein entsprechender Link. Außerdem können Sie
                                            sich jederzeit auch
                                            direkt auf dieser Website abmelden oder uns Ihren Widerruf über die am Ende
                                            dieser
                                            Datenschutzhinweise angegebene Kontaktmöglichkeit mitteilen.</p>
                                        <h3>Empfänger:</h3><br/>
                                        <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
                                        <p></p><h3>Drittlandtransfer:</h3><br/>
                                        <p>Die erhobenen Daten werden ggfs. in folgende Drittländer übertragen: </p>
                                        <p>Nein</p>
                                        <p>Folgende Datenschutzgarantien liegen vor: </p>
                                        <p></p><h3>Speicherdauer:</h3><br/>
                                        <p>Die Daten werden in diesem Zusammenhang nur verarbeitet, solange die
                                            entsprechende Einwilligung
                                            vorliegt. Danach werden sie gelöscht.</p>
                                        <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3><br/>
                                        <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein
                                            auf Basis Ihrer
                                            Einwilligung. Ohne bestehende Einwilligung können wir Ihnen unseren
                                            Newsletter leider nicht
                                            zusenden.</p>
                                        <h3>Widerruf der Einwilligung:</h3><br/>
                                        <p>Die Einwilligung zur Speicherung Ihrer persönlichen Daten und ihrer Nutzung
                                            für den
                                            Newsletterversand können Sie jederzeit mit Wirkung für die Zukunft
                                            widerrufen. Die Abmeldung
                                            kann über den in jeder E-Mail enthaltenen Link oder beim unten aufgeführten
                                            Datenschutzbeauftragten bzw. der für den Datenschutz zuständigen Person
                                            beantragt werden. </p>
                                        <p></p><h2>Kontaktformular</h2><br/>
                                        <h3>Art und Zweck der Verarbeitung:</h3><br/>
                                        <p>Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen
                                            Kommunikation mit Ihnen
                                            gespeichert. Hierfür ist die Angabe einer validen E-Mail-Adresse sowie Ihres
                                            Namens
                                            erforderlich. Diese dient der Zuordnung der Anfrage und der anschließenden
                                            Beantwortung
                                            derselben. Die Angabe weiterer Daten ist optional.</p>
                                        <h3>Rechtsgrundlage:</h3><br/>
                                        <p>Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt auf
                                            der Grundlage eines
                                            berechtigten Interesses (Art. 6 Abs. 1 lit. f DSGVO).</p>
                                        <p>Durch Bereitstellung des Kontaktformulars möchten wir Ihnen eine
                                            unkomplizierte Kontaktaufnahme
                                            ermöglichen. Ihre gemachten Angaben werden zum Zwecke der Bearbeitung der
                                            Anfrage sowie für
                                            mögliche Anschlussfragen gespeichert.</p>
                                        <p>Sofern Sie mit uns Kontakt aufnehmen, um ein Angebot zu erfragen, erfolgt die
                                            Verarbeitung der in
                                            das Kontaktformular eingegebenen Daten zur Durchführung vorvertraglicher
                                            Maßnahmen (Art. 6 Abs.
                                            1 lit. b DSGVO).</p>
                                        <h3>Empfänger:</h3><br/>
                                        <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
                                        <p></p><h3>Drittlandtransfer:</h3><br/>
                                        <p>Die erhobenen Daten werden ggfs. in folgende Drittländer übertragen: </p>
                                        <p>Nein</p>
                                        <p>Folgende Datenschutzgarantien liegen vor: </p>
                                        <p></p><h3>Speicherdauer:</h3><br/>
                                        <p>Daten werden spätestens 6 Monate nach Bearbeitung der Anfrage gelöscht.</p>
                                        <p>Sofern es zu einem Vertragsverhältnis kommt, unterliegen wir den gesetzlichen
                                            Aufbewahrungsfristen nach HGB und löschen Ihre Daten nach Ablauf dieser
                                            Fristen. </p>
                                        <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3><br/>
                                        <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Wir
                                            können Ihre Anfrage
                                            jedoch nur bearbeiten, sofern Sie uns Ihren Namen, Ihre E-Mail-Adresse und
                                            den Grund der Anfrage
                                            mitteilen.</p>
                                        <p></p><h2>Verwendung von Scriptbibliotheken (Google Webfonts)</h2><br/>
                                        <p>Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend
                                            darzustellen, verwenden
                                            wir auf dieser Website „Google Web Fonts“ der Google LLC (1600 Amphitheatre
                                            Parkway, Mountain
                                            View, CA 94043, USA; nachfolgend „Google“) zur Darstellung von
                                            Schriften.</p>
                                        <p>Weitere Informationen zu Google Web Fonts finden Sie unter <a
                                            href="https://developers.google.com/fonts/faq" rel="noopener nofollow"
                                            target="_blank">https://developers.google.com/fonts/faq</a> und in der
                                            Datenschutzerklärung von
                                            Google: <a href="https://www.google.com/policies/privacy/"
                                                       rel="noopener nofollow"
                                                       target="_blank">https://www.google.com/policies/privacy/</a>.</p>
                                        <p></p><h2>SSL-Verschlüsselung</h2><br/>
                                        <p>Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir
                                            dem aktuellen Stand
                                            der Technik entsprechende Verschlüsselungsverfahren (z. B. SSL) über
                                            HTTPS.</p>
                                        <p></p>
                                        <hr/>
                                        <h2>Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</h2><br/>
                                        <h3>Einzelfallbezogenes Widerspruchsrecht</h3><br/>
                                        <p>Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation
                                            ergeben, jederzeit
                                            gegen die Verarbeitung Sie betreffender personenbezogener Daten, die
                                            aufgrund Art. 6 Abs. 1
                                            lit. f DSGVO (Datenverarbeitung auf der Grundlage einer Interessenabwägung)
                                            erfolgt,
                                            Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmung
                                            gestütztes Profiling im
                                            Sinne von Art. 4 Nr. 4 DSGVO.</p>
                                        <p>Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr
                                            verarbeiten, es
                                            sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung
                                            nachweisen, die
                                            Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung
                                            dient der
                                            Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>
                                        <h3>Empfänger eines Widerspruchs</h3><br/>
                                        <p>{imprintData?.companyName}<br/>
                                            vertreten durch: {imprintData?.responsibleFirstName}&nbsp;{imprintData?.responsibleSecondName}<br/>
                                            {imprintData?.street}&nbsp;{imprintData?.houseNumber}<br/>
                                            {imprintData?.zip}&nbsp;{imprintData?.city}<br/></p>
                                        <hr/>
                                        <h2>Änderung unserer Datenschutzbestimmungen</h2><br/>
                                        <p>Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets
                                            den
                                            aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer
                                            Leistungen in
                                            der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services.
                                            Für Ihren
                                            erneuten Besuch gilt dann die neue Datenschutzerklärung.</p>
                                        <h2>Fragen an den Datenschutzbeauftragten</h2><br/>
                                        <p>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail
                                            oder wenden
                                            Sie sich direkt an die für den Datenschutz verantwortliche Person in unserer
                                            Organisation:</p>
                                        <p></p><p><em>Die Datenschutzerklärung wurde mithilfe der activeMind AG
                                        erstellt, den
                                        Experten für <a
                                            href="https://www.activemind.de/datenschutz/datenschutzbeauftragter/"
                                            target="_blank" rel="noopener">externe Datenschutzbeauftragte</a> (Version
                                        #2020-09-30).</em></p>
                                    </SoftTypography>
                                </Grid2>
                            </Grid2>}
                    </CardContent>
                </Card>
            </BasicLayout>
        </ThemeProvider>
    );
}
import {
    Backdrop,
    Card,
    CardContent,
} from "@mui/material";
import Grid2 from '@mui/material/Unstable_Grid2';
import {useEffect, useState} from "react";
import SoftTypography from "../components/SoftTypography";
import theme from "assets/theme";
import {ThemeProvider} from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import * as React from "react";
import BasicLayout from "../components/BasicLayout";
import SoftBox from "../components/SoftBox";
import {Vortex} from "react-loader-spinner";
import RetourenAPI from "../api/RetourenAPI";

export default function Imprint({token}) {
    const [loading, setLoading] = useState(true);
    const [imprintData, setImprintData] = useState(undefined);

    useEffect(() => {
        RetourenAPI.generateImprint(token).then(res => {
            setImprintData(res.data);
        }).catch(() => {
            setImprintData({"id":1,"companyName":"Aufzug Gräser GbR\nSimple Retouren","responsibleFirstName":"Philipp","username":"gcrimede@gmail.com","responsibleSecondName":"Gräser & Lara Aufzug","email":"gcrimede@gmail.com","phone":"04031108456","street":"Cuxhavener Straße","houseNumber":"386","zip":21149,"city":"Hamburg","country":"DE","vat":"DE336325022","complete":true})
        }).finally(() => setLoading(false));
    }, []);

    const loadingScene = () => (<SoftBox display="flex" justifyContent="center">
        <div className='pulse'>
            <Vortex
                visible={true}
                height="80"
                width="80"
                ariaLabel="vortex-loading"
                wrapperStyle={{}}
                wrapperClass="vortex-wrapper"
                colors={['#ffd6ff', '#e7c6ff', '#c8b6ff', '#b8c0ff', '#bbd0ff', '#cae9ff']}
            />
        </div>
    </SoftBox>)

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <BasicLayout
                title={undefined}
                description={undefined}
                color='primary'
                token={token}
            >
                <Card sx={{minHeight: '70vh', minWidth: 999}}>
                    <CardContent>
                        {loading ? <Backdrop
                                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                                open={loading}
                            >
                                {loadingScene()}
                            </Backdrop>
                            :
                            <Grid2 container direction='row' justifyContent='left' textAlign='left' spacing={2}>
                                <Grid2 item>
                                    <SoftTypography fontWeight='bold' variant='h3'>Impressum</SoftTypography>
                                    <SoftTypography variant='body2'>(Angaben gemäß §5 TMG)</SoftTypography>
                                    <SoftTypography variant='body2'>Gesetzliche Anbieterkennung:</SoftTypography>
                                    <SoftTypography style={{marginTop: 15}} variant='subtitle1'>
                                        {imprintData?.companyName}<br/>
                                        vertreten durch: {imprintData?.responsibleFirstName}&nbsp;{imprintData?.responsibleSecondName}<br/>
                                        {imprintData?.street}&nbsp;{imprintData?.houseNumber}<br/>
                                        {imprintData?.zip}&nbsp;{imprintData?.city}<br/>
                                        Deutschland<br/>
                                        Telefon: {imprintData?.phone}<br/>

                                        E-Mail: {imprintData?.email}<br/>
                                        {imprintData?.vat ? ('USt-IdNr.: ' + imprintData.vat) : ''}<br/>
                                    </SoftTypography>
                                </Grid2>
                            </Grid2>}
                    </CardContent>
                </Card>
            </BasicLayout>
        </ThemeProvider>
    );
}

import PropTypes from "prop-types";


import Grid from "@mui/material/Grid";


import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";


import PageLayout from "assets/theme/PageLayout";
import Footer from "../Footer";



function BasicLayout({title, description, image, children, token}) {
    return (
        <PageLayout>
            {
                <SoftBox
                    width="calc(100% - 2rem)"
                    borderRadius="lg"
                    sx={{
                        backgroundImage: ({functions: {linearGradient, rgba}, palette: {gradients}}) =>
                            image &&
                            `${linearGradient(
                                rgba(gradients.dark.main, 0.6),
                                rgba(gradients.dark.state, 0.6)
                            )}, url(${image})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                </SoftBox>
            }
            <SoftBox mt={{xs: title && description ? -26 : 5, lg: title && description ? -24 : 15}} px={1} width="calc(100% - 2rem)" mx="auto" >
                <Grid container spacing={1} justifyContent="center">
                    <Grid item>
                        {children}
                    </Grid>
                </Grid>
            </SoftBox>
            <Footer token={token}/>
        </PageLayout>
    );
}


BasicLayout.defaultProps = {
    title: "",
    description: "",
};


BasicLayout.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default BasicLayout;

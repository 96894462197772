import {useTheme} from '@mui/material/styles';
import {Chip, Divider, Grid, useMediaQuery} from '@mui/material';
import React from "react";
import SoftTypography from "../../components/SoftTypography";

const UploadDesignArea = (props) => {
    const theme = useTheme();
    const small = props.small;
    const files = props.files;

    return (
        <Grid container justifyContent="center" spacing={2} sx={{my: 1}}>
            <Grid item xs={12} sm={8}>
                <Grid container justifyContent="center" direction="row" alignItems="center" spacing={2}>
                    {small ? <SoftTypography variant={'h5'} color={theme.palette.primary.main}>Dateien hochladen</SoftTypography>
                        :
                        <>
                            <Grid item>
                                <SoftTypography variant='h5' color="inherit">
                                    Dateien hochladen
                                </SoftTypography>
                            </Grid>

                            <Grid item>
                                <SoftTypography variant="body2" align="center" style={{fontSize: '12px'}}>
                                    Klicke oder ziehe eine Datei über dieses Feld um zu beginnen<br/>
                                    Wir unterstützen <Chip label="PNG" size="small" color="primary" variant='outlined'/> &&nbsp;
                                    <Chip label="JPG" size="small" color="primary" variant='outlined'/><br/>
                                    Maximale Dateigröße: <strong>2MB</strong><br/>
                                    Maximale {files.length > 0 ? 'zusätzliche' : ''} Dateien: <strong>{2 - files.length}</strong><br/>
                                </SoftTypography>
                            </Grid>

                        </>
                    }
                </Grid>
            </Grid>
        </Grid>
    );
};

export default UploadDesignArea;

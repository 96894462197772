import * as React from "react";
import SoftTypography from "../../components/SoftTypography";
import SoftBox from "../../components/SoftBox";
import Cat404 from "assets/images/illustrations/cat_404.svg";
import SoftButton from "../../components/SoftButton";

export default function OrderNotFound(props) {
    const setError = props.onError;

    const handleBack = () => setError(undefined);

    return (<SoftBox>
        <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5} textAlign='center'>
                <img src={Cat404}/>
                <SoftTypography variant='h3'>Bestellung nicht gefunden</SoftTypography>
            </SoftBox>
        </SoftBox>
        <SoftBox mt={4} mb={1}>
            <SoftButton variant="gradient" color="primary" onClick={handleBack} fullWidth>
                Zurück zur Eingabe
            </SoftButton>
        </SoftBox>
    </SoftBox>)
}
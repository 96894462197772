import * as React from 'react';
import {Routes, Route, useSearchParams, useNavigate} from "react-router-dom";
import Main from "./steps/Main";
import ShopNotFound from "./steps/fallback/ShopNotFound";
import {useEffect, useState} from "react";
import RetourenAPI from "./api/RetourenAPI";
import Imprint from "./steps/Imprint";
import Datenschutz from "./steps/Datenschutz";

export default function App() {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [shopData, setShopData] = useState(undefined);
    const shop = searchParams.get("shop");

    useEffect(() => {
        if (!shop || shop === '' || shop === null) {
            navigate('error')
        }
        RetourenAPI.resolveShopToken(shop).then(res => {
            if (!res.data?.uniqueToken === shop)
                navigate('error')
            else {
                setShopData(res.data);
            }
        }).catch(error => {
            navigate('error')
        })
    }, [shop]);

    return <Routes>
        <Route path='/' element={<Main token={shop} data={shopData}/>}/>
        <Route path='/error' element={<ShopNotFound/>}/>
        <Route path='/impressum' element={<Imprint token={shop}/>}/>
        <Route path='/datenschutz' element={<Datenschutz token={shop}/>}/>
    </Routes>
}


import SearchIcon from "@mui/icons-material/Search";
import * as React from "react";
import {useState} from "react";
import SoftTypography from "../../components/SoftTypography";
import Cat404 from "assets/images/illustrations/cat_404.svg";
import SoftButton from "../../components/SoftButton";
import SoftBox from "../../components/SoftBox";
import curved9 from "../../assets/images/mainSiteBackground.jpg";
import CoverLayout from "../../components/CoverLayout";
import theme from "../../assets/theme";
import CssBaseline from "@mui/material/CssBaseline";
import {ThemeProvider} from "@emotion/react";

export default function ShopNotFound() {

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <CoverLayout
                title={"Shop nicht gefunden"}
                color='primary'
                description={"Der Shop bei dem du eine Retoure anlegen möchtest, wurde nicht gefunden."}
                image={curved9}
            >
                <SoftBox>
                    <SoftBox mb={2}>
                        <SoftBox mb={1} ml={0.5}>
                            <img src={Cat404}/>
                        </SoftBox>
                    </SoftBox>
                </SoftBox>
            </CoverLayout>
        </ThemeProvider>)
}
import {
    Backdrop,
    Card,
    CardContent,
    Checkbox,
    Divider,
    FormControl,
    MenuItem,
    Select, Tooltip
} from "@mui/material";
import SoftButton from "../components/SoftButton";
import SoftTypography from "../components/SoftTypography";
import Grid2 from '@mui/material/Unstable_Grid2';
import {useEffect, useState} from "react";
import {useTheme} from '@mui/material/styles';
import SoftInput from "../components/SoftInput";
import Separator from "../components/Separator";
import {Warning} from "@mui/icons-material";
import DropzoneArea from "./nested/Dropzone";
import RetourenAPI from "../api/RetourenAPI";
import moment from "moment";
import SoftBox from "../components/SoftBox";
import {Vortex} from "react-loader-spinner";
import UploadImageArea from "./nested/UploadImageArea";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const reasons = [
    'Es hat mir nicht gefallen',
    'Es passt mir nicht',
    'Die Farbe ist nicht wie angegeben',
    'Das Produkt ist beschädigt',
    'Ich möchte keinen Grund angeben'
];

const shippingVendors = ['DHL', 'Eigener'];

function getStyles(reason, selected, theme) {
    return {
        fontWeight:
            selected === reason
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function ChooseProduct(props) {
    const order = props.order;
    const token = props.token;
    const data = props.data;
    const setSubmitted = props.setSubmitted;
    const [checkedProducts, setCheckedProducts] = useState([]);
    const theme = useTheme();
    const [reason, setReason] = useState(undefined);
    const [remarks, setRemarks] = useState(undefined);
    const [shippingVendor, setShippingVendor] = useState(undefined);
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    const handleChange = (event) => {
        const {
            target: {value},
        } = event;
        setReason(value);
    };

    const getDaysBetween = (date) => {
        const now = new Date();
        const then = new Date(date);
        const averageShippingTime = 4;
        return moment(now).diff(then, 'days') - averageShippingTime;
    }

    const checkValidity = () => {
        if (reason !== 'Das Produkt ist beschädigt' && getDaysBetween(order?.informations?.timestamp) > data?.maximumReturnDays)
            return "Maximaler Rückgabezeitraum (" + data?.maximumReturnDays + " Tage) überschritten";
        if (checkedProducts.length === 0)
            return false;
        if (reason === undefined)
            return "Bitte wähle einen Grund aus";
        if (shippingVendor === undefined)
            return "Bitte wähle einen Versanddienstleister aus";
        if (reason === 'Das Produkt ist beschädigt' && files.length === 0)
            return "Bei beschädigten Produkten musst du ein Bild anhängen";
        return true;
    }

    const submit = async () => {
        const filesArray = [];
        for (const f of files) {
            const b64 = await toBase64(f);
            filesArray.push(b64)
        }
        setLoading(true);

        RetourenAPI.createRetoure(order.orderId, order.plz, JSON.stringify(checkedProducts), reason, (remarks ? btoa(remarks) : null), (files.length > 0 ? JSON.stringify(filesArray) : null), token).then(res => {
            setSubmitted(true);
        }).finally(() => {
            window.open("https://www.dhl.de/de/privatkunden/pakete-versenden/online-frankieren.html?type=RetoureLandingPage&rosyId=c71c4046b157400f84b33082c84dbc07", "_blank", "noreferrer");
            setLoading(false);
        });
    }

    const loadingScene = () => (<SoftBox display="flex" justifyContent="center">
        <div className='pulse'>
            <Vortex
                visible={true}
                height="80"
                width="80"
                ariaLabel="vortex-loading"
                wrapperStyle={{}}
                wrapperClass="vortex-wrapper"
                colors={['#ffd6ff', '#e7c6ff', '#c8b6ff', '#b8c0ff', '#bbd0ff', '#cae9ff']}
            />
        </div>
    </SoftBox>)

    return (
        <>
            <img
                className='logoImg'
                src={data?.logo}
                height={50}/><br/>
            <Card sx={{minHeight: '70vh', maxWidth: 750, minWidth: 350}} className='glassMorphCard'>
                <CardContent>
                    {loading ? <Backdrop
                            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                            open={loading}
                        >
                            {loadingScene()}
                        </Backdrop>
                        :
                        <Grid2 container direction='row' justifyContent='center' textAlign='center' spacing={2}>
                            <Grid2 item>
                                <SoftTypography fontWeight='bold' variant='h3'>Bestellung
                                    #{order?.orderId}</SoftTypography>
                                <SoftTypography variant='subtitle1'>Welche Produkte möchtest du zurück
                                    senden?</SoftTypography>
                            </Grid2>
                            <Grid2 item xs={12}>
                                <Divider orientation='horizontal'/>
                            </Grid2>
                            {order?.products?.map((product, index) => (
                                <Grid2 item xs={12}>
                                    <Card sx={{
                                        cursor: 'pointer',
                                        boxShadow: checkedProducts.some(p => p.index === index) ? '0 0 0 2px purple' : ''
                                    }}
                                          onClick={() => {
                                              if (product.disabled === true)
                                                  return;
                                              if (checkedProducts.some(p => p.index === index)) {
                                                  setCheckedProducts(checkedProducts.filter(p => p.index !== index))
                                              } else
                                                  setCheckedProducts(prevState => [...prevState, {
                                                      ...product,
                                                      index: index
                                                  }]);
                                          }}
                                    >
                                        <Grid2 container xs={12} direction='row' lineHeight='70px'>
                                            <Grid2 item xs={2} lg={1}>
                                                {product.disabled ? <Grid2 item xs={4}>
                                                        <Tooltip
                                                            title={"Bitte melde dich direkt bei uns, um dieses Produkt zu retournieren, da eine Rücksendung über das Portal nicht möglich ist."}>
                                                            <Warning color={'warning'}/>
                                                        </Tooltip>
                                                    </Grid2>
                                                    :
                                                    <Checkbox
                                                        disabled={product.disabled}
                                                        size='large'
                                                        color='secondary'
                                                        checked={checkedProducts.some(p => p.index === index)}
                                                    />
                                                }
                                            </Grid2>
                                            <Grid2 item xs={2} lg={1}>
                                                <div style={{
                                                    position: 'relative',
                                                    height: '90%',
                                                    width: '100%',
                                                    background: "#fff url(" + product.img + ") center center/contain no-repeat",
                                                    top: '5%',
                                                    borderRadius: '15px'
                                                }}>
                                                    &nbsp;
                                                </div>
                                            </Grid2>
                                            <Grid2 item xs={5} textAlign='left' sx={{mt: 0.5, ml: 2}}>
                                                <SoftTypography variant='body1'
                                                                color={product.disabled ? 'text' : 'primary'}>{product.name}</SoftTypography>
                                                <SoftTypography variant='body2'
                                                                color={product.disabled ? 'text' : ''}>Menge: {product.amount}</SoftTypography>
                                            </Grid2>
                                        </Grid2>
                                    </Card>
                                </Grid2>
                            ))}
                            {checkedProducts.length > 0 && <>
                                <Grid2 item xs={12}>
                                    <Separator text='Noch ein paar Infos..'/>
                                </Grid2>
                                <Grid2 item xs={12} textAlign='left'>
                                    <FormControl fullWidth>
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            Grund wählen*
                                        </SoftTypography>
                                        <Select
                                            labelId="select-reason"
                                            fullWidth
                                            required
                                            displayEmpty
                                            value={reason}
                                            onChange={handleChange}
                                            input={<SoftInput/>}
                                            renderValue={(selected) => {
                                                if (selected === undefined)
                                                    return <em>Wieso möchtest du retounieren?</em>;
                                                return selected;
                                            }}
                                            MenuProps={MenuProps}
                                            size='large'
                                        >
                                            <MenuItem disabled value="">
                                                <em>Wieso möchtest du retounieren?</em>
                                            </MenuItem>
                                            {reasons.map((r) => (
                                                <MenuItem
                                                    key={r}
                                                    value={r}
                                                    style={getStyles(r, reason, theme)}
                                                >
                                                    {r}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid2>
                                <Grid2 item xs={12} textAlign='left'>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        Möchtest du uns sonst noch etwas mitteilen?
                                    </SoftTypography>
                                    <SoftInput multiline value={remarks ?? ''}
                                               onChange={(e) => setRemarks(e.target.value)}/>
                                </Grid2>
                                <Grid2 item xs={12} textAlign='left'>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        Möchtest du uns Bilder
                                        anhängen?{reason === 'Das Produkt ist beschädigt' ? '*' : ''}
                                    </SoftTypography>
                                    <DropzoneArea files={files} setFiles={setFiles}/>
                                </Grid2>
                                <Grid2 item xs={12} textAlign='left'>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        Versanddienstleister wählen
                                    </SoftTypography>
                                    <Grid2 container direction='row' textAlign='center' spacing={2}>
                                        {shippingVendors.map(sV => (<Grid2 item md={3} xs={6}>
                                            <SoftButton
                                                disableElevation
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="outlined"
                                                color={shippingVendor === sV ? 'success' : 'secondary'}
                                                component="label"
                                                onClick={() => setShippingVendor(sV)}
                                            >
                                                {sV}
                                            </SoftButton>
                                        </Grid2>))}
                                        <Grid2 item xs={12}>
                                            {shippingVendor === 'Eigener' &&
                                                <SoftTypography variant='caption' color='secondary'>Bitte sende dein
                                                    Paket
                                                    an folgende Adresse:<br/>
                                                    <strong>Simple Retouren</strong><br/>
                                                    <strong>Aufzug Gräser GbR</strong><br/>
                                                    <strong>Cuxhavener Straße 386</strong><br/>
                                                    <strong>21149 Hamburg</strong>
                                                </SoftTypography>}
                                        </Grid2>
                                    </Grid2>
                                </Grid2>
                                {checkValidity() !== true && <Grid2 item xs={12}><SoftTypography variant='caption'
                                                                                                 color='error'>{checkValidity()}</SoftTypography></Grid2>}
                                <Grid2 item xs={5}>
                                    <SoftButton color={(checkValidity() === true) ? 'primary' : 'secondary'}
                                                variant="gradient"
                                                disabled={!(checkValidity() === true)}
                                                onClick={async () => await submit()}>
                                        {shippingVendor === 'DHL' ? 'Versandetikett erhalten' : 'Paket ankündigen'}
                                    </SoftButton>
                                </Grid2>
                            </>}
                        </Grid2>}
                </CardContent>
            </Card>
        </>
    );
}
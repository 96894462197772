import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import {ThemeProvider} from '@mui/material/styles';
import curved9 from "assets/images/mainSiteBackground.jpg"
import "assets/css/Animations.css";

import theme from "assets/theme";
import SoftButton from "components/SoftButton";
import CoverLayout from "components/CoverLayout";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import {useState} from "react";
import SoftInput from "components/SoftInput";
import {Vortex} from "react-loader-spinner";
import SearchIcon from '@mui/icons-material/Search';
import FindOrder from "steps/FindOrder";
import OrderNotFound from "./nested/404";
import BasicLayout from "../components/BasicLayout";
import ChooseProduct from "./ChooseProducts";
import {Route, useSearchParams} from "react-router-dom";
import FinalSend from "./nested/FinalSend";

const Main = ({token, data}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setSubmitted] = useState(false);
    const [order, setOrder] = useState(undefined);
    const [error, setError] = useState(undefined);

    const loadingScene = () => (<SoftBox display="flex" justifyContent="center">
        <div className='pulse'>
            <Vortex
                visible={true}
                height="80"
                width="80"
                ariaLabel="vortex-loading"
                wrapperStyle={{}}
                wrapperClass="vortex-wrapper"
                colors={['#ffd6ff', '#e7c6ff', '#c8b6ff', '#b8c0ff', '#bbd0ff', '#cae9ff']}
            />
        </div>
    </SoftBox>)

    const orderScene = () => {
        if (isSubmitted)
            return <FinalSend order={order}/>
        if (error !== undefined) {
            return <OrderNotFound onError={setError}/>
        } else {
            return <FindOrder onSearch={setIsLoading} onError={setError} setOrder={setOrder} token={token}/>;
        }
    }

    return <ThemeProvider theme={theme}>
        <CssBaseline/>
        {(order && Object.keys(order).length > 0) && !isSubmitted ?
            <BasicLayout
                title={undefined}
                description={undefined}
                color='primary'
                token={token}
            >
                <ChooseProduct order={order} token={token} setSubmitted={setSubmitted} data={data}/>
            </BasicLayout>
            :
            <CoverLayout
                title={error?.text ?? (isSubmitted ? "Auf großer Reise" : "Eine Retoure aufgeben")}
                color='primary'
                description={error?.description ?? (isSubmitted ? "Fast geschafft! Die Retoure ist bei uns angelegt, bitte sende die retounierten Produkte nun an unsere Adresse. Wir melden uns sobald das Paket angekommen ist!" : "Nutze dieses Portal um eine Retoure zu beauftragen. Wir benötigen lediglich einige Infos über dich und deine Bestellung und schon kann es losgehen.")}
                image={data?.background ?? curved9}
                logo={data?.logo ?? ''}
                token={token}
            >
                {isLoading ? loadingScene() : orderScene()}
            </CoverLayout>}
    </ThemeProvider>
}

export default Main;
import HttpService from "./HttpService";

const API_URL = "https://api.simple-retouren.de/api/public/retouren/";

class RetourenAPI {
    resolveShopToken = (token) => {
        return HttpService.getAxiosClient()
            .get(API_URL + token)
            .then(response => {
                return response;
            });
    };

    findOrder = (token, orderId, zip) => {
        return HttpService.getAxiosClient()
            .get(API_URL + token + '/order?orderId=' + orderId + "&zip=" + zip)
            .then(response => {
                return response;
            });
    };

    generateImprint = (token) => {
        return HttpService.getAxiosClient()
            .get(API_URL + token + '/imprint')
            .then(response => {
                return response;
            });
    };

    createRetoure = (orderId, zip, productsJSONArray, cause, remarksB64, imagesB64JSONArray, token) => {
        let formData = new FormData();
        formData.append('orderId', orderId);
        formData.append('zip', zip);
        formData.append('productsJSONArray', productsJSONArray);
        formData.append('cause', cause);
        if (remarksB64)
            formData.append('remarksB64', remarksB64);
        if (imagesB64JSONArray)
            formData.append('imagesB64JSONArray', imagesB64JSONArray);


        return HttpService.getAxiosClient()
            .post(API_URL + token, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                }
            )
            .then(response => {
                return response;
            });
    };
}

export default new RetourenAPI();
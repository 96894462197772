
import Divider from "@mui/material/Divider";


import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function Separator({text}) {

  return (
    <SoftBox position="relative" py={0.25} sx={{mt: 1, mb: 1}}>
      <Divider />
      <SoftBox
        bgColor="#F8F9FA"
        borderRadius='10px'
        position="absolute"
        top="50%"
        left="50%"
        px={1.5}
        lineHeight={1}
        sx={{ transform: "translate(-50%, -60%)" }}
      >
        <SoftTypography variant="button" fontWeight="medium" color="secondary">
            {text}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

export default Separator;

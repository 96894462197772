
import Grid from "@mui/material/Grid";


import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import LinkedInIcon from "@mui/icons-material/LinkedIn";


import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function Footer({token}) {
    return (
        <SoftBox component="footer" py={6}>
            <Grid container justifyContent="center">
                <Grid item xs={10} lg={8}>
                    <SoftBox display="flex" justifyContent="center" flexWrap="wrap" mb={3}>
                        <SoftBox mr={{xs: 2, lg: 3, xl: 6}}>
                            <SoftTypography component="a" href={"/impressum?shop=" + token} variant="body2" className='invertedText'>
                                Impressum
                            </SoftTypography>
                        </SoftBox>
                        <SoftBox>
                            <SoftTypography component="a" href={"/datenschutz?shop=" + token} variant="body2" className='invertedText'>
                                Datenschutzerklärung
                            </SoftTypography>
                        </SoftBox>
                    </SoftBox>
                </Grid>

                <Grid item xs={12} lg={8} sx={{textAlign: "center"}}>
                    <SoftTypography variant="body2" className='invertedText'>
                        Powered by Simple Retouren
                    </SoftTypography>
                </Grid>
            </Grid>
        </SoftBox>
    );
}

export default Footer;

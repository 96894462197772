import {useState} from "react";
import SoftButton from "../../components/SoftButton";
import UploadImageArea from "./UploadImageArea";
import SoftTypography from "../../components/SoftTypography";
import {useTheme} from "@emotion/react";
import {DeleteForever} from "@mui/icons-material";
import Dropzone from "react-dropzone";
import {useMediaQuery} from "@mui/material";
import Grid from "@mui/material/Grid";

export default function DropzoneArea(props) {
    const theme = useTheme();
    const files = props.files;
    const setFiles = props.setFiles;
    const [error, setError] = useState(undefined);
    const matchDownXs = useMediaQuery(theme.breakpoints.down('sm'));

    const handleError = (msg) => {
        setError(msg);
        setTimeout(() => {
            setError(undefined);
        }, 5000);
    }

    const handleUpload = (file) => {
        if (!".png,.PNG,.jpg,.JPG,.jpeg,.JPEG".includes(file?.name.split('.').pop())) {
            setError("Mögliche Dateiformate: .png, .jpg");
            return;
        }
        if (files.length >= 2) {
            handleError("Maximal 2 Dateien")
            return;
        }
        if (file?.size > (2 * (1024 * 1024))) {
            handleError("Maximale Dateigröße: 2MB")
            return;
        }
        setFiles(prevState => [...prevState, file])
        setError(undefined);
    };

    const handleDelete = (index) => {
        const f = [...files];
        f.splice(index, 1);
        setFiles(f);
    }

    return (<>
        <Dropzone accept='.png,.PNG,.jpg,.JPG,.jpeg,.JPEG'
                  maxFilesize={2}
                  multiple={true}
                  maxFiles={2}
                  onDrop={acceptedFiles => acceptedFiles.length === 0 ? handleError("Bitte lade eine Datei vom Typ PNG, AI oder DST hoch") : handleUpload(acceptedFiles[0])}>
            {({getRootProps, getInputProps}) => (
                <section>
                    <div {...getRootProps()}>
                        <input {...getInputProps()}/>
                        <SoftButton
                            disableElevation
                            fullWidth
                            size="large"
                            type="submit"
                            variant="outlined"
                            color={error ? 'error' : 'secondary'}
                            component="label"
                        >
                            <UploadImageArea files={files}
                                             small={matchDownXs}/>
                        </SoftButton>
                    </div>
                </section>
            )}
        </Dropzone>
        {files && files.length > 0 &&
            <div style={{marginTop: 1}}>
                <Grid item xs={12} md={12} sx={{m: 1}}>
                    <SoftTypography variant='h5'>Dateien:</SoftTypography>
                    {files.map((file, index) => (
                        <div key={index}>
                            <SoftTypography variant={'h6'}>
                                <span style={{
                                    color: theme.palette.error.main,
                                    cursor: 'pointer'
                                }}
                                      onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          handleDelete(index);
                                      }}
                                >
                                    <DeleteForever/>
                                </span>
                                {file.path}
                            </SoftTypography>
                        </div>
                    ))}
                </Grid>
            </div>}
    </>)
}
import axios from "axios";

const HttpMethods = {
    GET: 'GET',
    POST: 'POST',
    DELETE: 'DELETE',
    OPTIONS: 'OPTIONS',
};

const _axios = axios.create();

const configure = () => {
    _axios.interceptors.request.use();
};

const getAxiosClient = () => _axios;

const HttpService = {
    HttpMethods,
    configure,
    getAxiosClient,
};

export default HttpService;
import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import App from "App";
import "assets/css/basic.css";


import {SoftUIControllerProvider} from "context";

let user = localStorage.getItem("user");
user = JSON.parse(user);


ReactDOM.render(
    <SoftUIControllerProvider>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </SoftUIControllerProvider>,
    document.getElementById("root")
);

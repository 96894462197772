import SoftBox from "../components/SoftBox";
import SoftTypography from "../components/SoftTypography";
import SoftInput from "../components/SoftInput";
import SoftButton from "../components/SoftButton";
import SearchIcon from "@mui/icons-material/Search";
import * as React from "react";
import {useState} from "react";
import RetourenAPI from "../api/RetourenAPI";

export default function FindOrder(props) {
    const onSubmit = props.onSearch;
    const token = props.token;
    const setError = props.onError;
    const setOrder = props.setOrder;

    const [formData, setFormData] = useState({
        'plz': '',
        'orderId': ''
    });


    const handleFormData = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value.replace('#', ''),
        });
    };

    const submitFormData = (e) => {
        e.preventDefault();
        onSubmit(true);
        RetourenAPI.findOrder(token, formData.orderId, formData.plz).then(res => {
            setOrder({...res.data, orderId: formData.orderId, plz: formData.plz});
        }).catch(error => {
            setError(true);
        }).finally(() => onSubmit(false))
    };

    return (<SoftBox component="form" role="form">
        <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Bestellnummer*
                </SoftTypography>
            </SoftBox>
            <SoftInput
                type="text"
                name="orderId"
                required
                onChange={handleFormData}
                placeholder="Bestellummer"
                value={formData?.orderId}
            />
        </SoftBox>
        <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Postleitzahl*
                </SoftTypography>
            </SoftBox>
            <SoftInput type="zip" name="plz" value={formData?.plz} onChange={handleFormData}
                       placeholder="Postleitzahl"
                       required
                       onKeyPress={(e) => {
                           if (e.key === 'Enter' && formData?.orderId !== '' && formData?.plz !== '') {
                               submitFormData(e);
                           }
                       }}/>
            <SoftTypography variant="caption">
                Gib hier die Postleitzahl an, welche auch für die Bestellung genutzt wurde.
            </SoftTypography>
        </SoftBox>

        <SoftBox mt={4} mb={1}>
            <SoftButton variant="gradient" color="primary" onClick={submitFormData} fullWidth
                        disabled={formData?.orderId === '' || formData?.plz === ''}>
                Bestellung finden <SearchIcon sx={{transform: 'scaleX(-1)', ml: 1}} strokeWidth={2}/>
            </SoftButton>
        </SoftBox>
    </SoftBox>)
}
import * as React from "react";
import SoftTypography from "../../components/SoftTypography";
import SoftBox from "../../components/SoftBox";
import Parcel from "assets/images/parcel_with_address.png";
import {useMediaQuery} from "@mui/material";
import {useTheme} from "@emotion/react";

export default function FinalSend(props) {
    const theme = useTheme();
    const matchDownXs = useMediaQuery(theme.breakpoints.down('sm'));
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    return (<SoftBox>
        <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5} textAlign='center'>
                <SoftTypography color='error' variant='caption'>Hast du DHL als Versanddienstleister ausgewählt aber wurdest nicht weitergeleitet?
                    <a target='_blank' href='https://www.dhl.de/de/privatkunden/pakete-versenden/online-frankieren.html?type=RetoureLandingPage&rosyId=c71c4046b157400f84b33082c84dbc07'><strong> Klicke hier</strong></a>
                </SoftTypography>
                <div style={{
                    position: 'relative',
                    height: '100%',
                    minHeight: '50vh',
                    minWidth: matchDownXs ? '90vw' : matchUpMd ? '20vw' : '35vw',
                    background: 'url('+ Parcel + ') center center/cover no-repeat',
                    textAlign: 'left'
                }}>
                </div>
            </SoftBox>
        </SoftBox>
    </SoftBox>)
}